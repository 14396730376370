/* Applies to all Amplify primitives in the APP */
.amplify-button[data-variation='primary'] {
    background: rgba(0, 103, 255, 1);
    /* primary color in colors.js */
    border-radius: 20px;
    width: 200px;
    align-self: center;

    /* border: 0px solid;
    width: 200px;
    margin-left: auto;
    margin-right: auto; */
}

[data-amplify-authenticator] [data-amplify-router] {
    /* border-end-end-radius: var(--amplify-radii-large); */
    /* border-end-start-radius: var(--amplify-radii-large); */
    /* border-start-end-radius: var(--amplify-radii-large); */
    /* border-start-start-radius: var(--amplify-radii-large); */
    border: none;
    box-shadow: none;
    text-align: center;
}