/* Styles below used in old Home.js and Consent.js */

.container {
    padding: 16px;
    background-color: #fff;
}

.header {
    text-align: center;
    margin-bottom: 32px;
}

.logo {
    width: 140px;
    height: 140px;
}

.logo2 {
    width: 96px;
    height: 96px;
}

.title {
    font-size: 24px;
    font-weight: bold;
    margin-top: 16px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.subtitle {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 16px;
}

.description {
    font-size: 16px;
    text-align: center;
    margin: 0 32px 16px;
}