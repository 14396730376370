/* App.css: added when creating private routes
#root: a div with the id "root" where the entire React application is mounted.*/

#root {
  text-align: center;
  margin: 0;
}

nav {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

/* disable text selection. If text selection is needed in particular areas, we will activate it on demand */
.disable-text-selection {
  -moz-user-select: none;
  /* firefox */
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE*/
  user-select: none;
  /* Standard syntax */
}

/* Needed to push the bottom footer down */
.app {
  min-height: calc(100vh - 55px);
  /* Jarda review how to handle this: WelcomeFooter.js and DashboardFooter.js */
  display: flex;
  flex-direction: column;
}

/* Media query for screens smaller than or equal to 768px */
@media (max-width: 768px) {
  .app {
    min-height: calc(100vh - 50px);
    /* Adjust the value for smaller screens */
  }
}

.app main {
  flex-grow: 1;
}