/* Inter Thin */
@font-face {
    font-family: 'Inter';
    src: url('./Inter-Thin.ttf') format('truetype');
    font-weight: 100;
}

/* Inter Extra Light */
@font-face {
    font-family: 'Inter';
    src: url('./Inter-ExtraLight.ttf') format('truetype');
    font-weight: 200;
}

/* Inter Light */
@font-face {
    font-family: 'Inter';
    src: url('./Inter-Light.ttf') format('truetype');
    font-weight: 300;
}

/* Inter Regular */
@font-face {
    font-family: 'Inter';
    src: url('./Inter-Regular.ttf') format('truetype');
    font-weight: 400;
}

/* Inter Medium */
@font-face {
    font-family: 'Inter';
    src: url('./Inter-Medium.ttf') format('truetype');
    font-weight: 500;
}

/* Inter SemiBold */
@font-face {
    font-family: 'Inter';
    src: url('./Inter-SemiBold.ttf') format('truetype');
    font-weight: 600;
}

/* Inter Bold */
@font-face {
    font-family: 'Inter';
    src: url('./Inter-Bold.ttf') format('truetype');
    font-weight: 700;
}

/* Inter Extra Bold */
@font-face {
    font-family: 'Inter';
    src: url('./Inter-ExtraBold.ttf') format('truetype');
    font-weight: 800;
}

/* Inter Black */
@font-face {
    font-family: 'Inter';
    src: url('./Inter-Black.ttf') format('truetype');
    font-weight: 900;
}